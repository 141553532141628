<template>
  <div v-if="this.$route.name == 'Results'">
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Results', route: 'Results' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <form class="form" v-on:submit.prevent="GetTestSearch">
          <div class="col-2">
            <label for="form.search">Filter Tests</label>
            <input
              type="text"
              v-model="form.search"
              class="input"
              placeholder="Search your tests..."
            />
          </div>
          <div class="col-3">
            <label>Filter by Network</label>
            <input
              id="onlyuser-true"
              type="radio"
              v-model="onlyuser"
              :value="true"
            />
            <label style="display: inline" for="onlyuser-true">My Tests</label>
            <input
              id="onlyuser-false"
              type="radio"
              v-model="onlyuser"
              :value="false"
            />
            <label style="display: inline" for="onlyuser-false"
              >My Network Tests</label
            >
          </div>
          <div class="col-1">
            <button
              class="btn btn-green"
              v-if="this.downloadList.length"
              v-on:click.prevent ="DownloadTests"
            >
              Download Selected
            </button>
          </div>
          <div class="col-1">
            <button
              class="btn btn-green"
              v-if="this.downloadList.length >= 2"
              v-on:click.prevent ="DownloadCombined"
            >
              Download Combined
            </button>
          </div>
          <div class="col-1.5">
            <label for="form.calendar">From :</label>
            <input
              type="date"
              class="input"
              v-model="form.from"
              placeholder="From"
            />
          </div>
 
          <div class="col-1.5">
            <label for="form.calendar">To :</label>
            <input
              type="date"
              class="input"
              v-model="form.to"
              placeholder="To"
            />
            
          </div>
          <div class="col-2">
            <label for="form.calendar"></label>
            <button
              class="btn btn-green"
              v-if="(this.form.from != '')&&(this.form.to != '')"
              v-on:click.prevent ="Downloadhistory"
            >
              Download
            </button>
          </div>
          
        </form>
      </div>
      <div class="row">
        <div class="col-12">
          <div
            v-for="(row, index) in Tests"
            :key="row"
            class="test-placeholder"
          >
            <div v-on:click="GotoInfo(row.id, index)" class="test-result">
              <div class="test-block">
                <div class="test-id">
                  <small>
                  {{ row.uuid !== null ? row.uuid : getIndex(index)}}
                  </small>
                </div>
                <!-- <div class="test-id">#{{ getuniqueID(row.test_type,row.id,row.businesscountry) }}</div> -->
                <div class="test-animalid">
                  <small>Sample ID</small>
                  {{ row.cow_id}}
                </div>
              </div>
              <div class="test-block">
                <div class="test-id"></div>
                <div class="test-animalid">
                  <small>Client Name</small>
                  {{ naCheck(row.client_name)}}
                </div>
              </div>
              <div class="test-block">
                <div class="test-id"></div>
                <div class="test-animalid">
                  <small>Herd number</small>
                  {{ naCheck(row.herd_number)}}
                </div>
              </div>
              <div class="test-block">
                <div class="test-id"></div>
                <div class="test-animalid">
                  <small>Test Type</small>
                  {{ testName(row.test_type)}}
                </div>
              </div>
              <div class="test-block">
                <div class="test-id"></div>
                <div class="test-animalid">
                  <small>Animal Type</small>
                  {{ animalType(row.animaltype)}}
                </div>
              </div>

              <div class="test-block">
                <div class="test-status">
                  <span
                    v-if="row.status == 'green'"
                    class="icon icon-heart"
                    style="color: #04a394"
                  ></span>
                  <span
                    v-else-if="row.status == 'red'"
                    class="icon icon-warning"
                    style="color: #d50014"
                  ></span>
                  <span
                    v-else-if="row.status == 'failed'"
                    class="icon icon-warning"
                    style="color: grey"
                  ></span>
                  <span v-else class="icon icon-heart"></span>
                </div>
                <div class="test-date">{{ TimeParse(row.created_at) }}</div>
              </div>
            </div>
            <div
              v-if="(row.status === 'green' || row.status === 'red')&&(row.test_type!=3)&&(row.test_type!=4)"
              style="
                height: 25px;
                background-color: #04a394;
                width: 80%;
                display: block;
                margin-left: 10%;
                border-radius: 0px 0px 10px 10px;
              "
              >
              
            
              <label style="color: #fff; line-height: 25px">
                <input
                  type="checkbox"
                  id="testfordownload"
                  v-model="downloadList"
                  :value="`${row.id},${row.test_type},${row.animaltype}`"
                />
                Download Result
              </label>
            </div>
            <div
              v-if="(row.status === 'green' || row.status === 'red')&&(row.test_type==3)"
                style="
                  height: 25px;
                  background-color: #A599BA;
                  width: 80%;
                  display: block;
                  margin-left: 10%;
                  border-radius: 0px 0px 10px 10px;
                ">
              
            
              <label style="color: #fff; line-height: 25px">
                <input
                  type="checkbox"
                  id="testfordownload"
                  v-model="downloadList"
                  :value="`${row.id},${row.test_type},${row.animaltype}`"
                />
                Download Fluke Result
              </label>
            </div>
            <div
              v-if="(row.status === 'green' || row.status === 'red')&&(row.test_type==4)"
                style="
                  height: 25px;
                  background-color: #e0adba;
                  width: 80%;
                  display: block;
                  margin-left: 10%;
                  border-radius: 0px 0px 10px 10px;
                ">
              
            
              <label style="color: #fff; line-height: 25px">              
              </label>
            </div>            
          </div>
        </div>
      </div>
      <div class="col-11">
        <div class="btn-group float-right">
          <div class="btn btn-green" v-on:click="GetMoreTest">
            Load More Results
          </div>
        </div>

        <!-- <div
          class="btn btn-green"
          v-if="tests.length > 0"
          v-on:click="DownloadTests"
        >
          <span class="icon icon-cloud-download"></span> DOWNLOAD TESTS -
          {{ tests.length }}
        </div> -->
      </div>
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { useStore } from "vuex";
import Navigation from "../components/Navigation.vue";
import html2pdf from "html2pdf.js";
import { PDFDocument } from "pdf-lib";
const { Parser } = require('json2csv');
export default {
  name: "Results",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      Tests: [],
      onlyuser: true,//changed this
      form: {
        search: "",
        limit: 10,
        offset: 0,
        onlyuser: true,// and this
        from: "",
        to:""
      },
      TotalTests: 0,
      count: 0,
      scroll: 0,
      maxScroll: 0,
      downloadList: [],
      pdfArray: [],
    };
  },
  components: {
    Navigation,
  },
  methods: {

    async DownloadTests(){
      // console.log("From downloadtest ",this.downloadList);
      this.store
      .dispatch("Tests/DownloadMultiResults",this.downloadList )
      .then((result) => {
        var arrays = [];
        result.forEach((rows) =>{
          if (rows.test_type !=3){
            const coccidiaValue = rows.coccidia;
            let strongyloidesValue = rows.strongyloides;
            let coccidia_status = "";
            if (coccidiaValue >= 0 && coccidiaValue < 1000) {
              coccidia_status = "Low";
            } else if (coccidiaValue >= 1000 && coccidiaValue < 2000) {
              coccidia_status = "Medium";
            } else if (coccidiaValue >= 2000 && coccidiaValue < 4000) {
              coccidia_status = "High";
            } else if (coccidiaValue >= 4000) {
              coccidia_status = "Very High";
            } else if (coccidiaValue == 0){
              coccidia_status = "<30";
            } else if (coccidiaValue == -1){
              coccidia_status = "Invalid";
            }else if(coccidiaValue == -2){
              coccidia_status = "-";
            }
            if (rows.strongyloides == -1){
              strongyloidesValue = "Invalid";

            }

            
             
            const status = 
              rows.status == 'red'
                ? "Infected"
                : rows.status == "green"
                ? "Not Infected"
                : rows.status == "failed"
                ? "Failed"
                :"In Progress";


              if(rows.animaltype!=3){
                let animal_type = 'Bovine';
                if (rows.animaltype ==2){
                  animal_type = 'Ovine';
                }
                else if (rows.animal_type == 4){
                  animal_type = 'Caprine';
                }


              arrays.push({
                  "Sample ID": rows.cow_id,
                  "UUID": rows.uuid,
                  "Status": status,
                  "Test Type" : "FEC Test",
                  "Animal Type": animal_type,
                  "Sample Type": rows.sampletype == 1 ?"Individual" : "Pooled",
                  "Date of Test": rows.created_date,              
                  "Strongyle (EPG)": rows.strongyle == 0 ? "<30" : rows.strongyle == -2 ? "-": rows.strongyle,
                  "Nematodirus (EPG)": rows.nematodirus == 0 ? "<30": rows.nematodirus == -2 ? "-" : rows.nematodirus,
                  "Coccidia (OPG)": coccidia_status,
                  "Strongyloides (EPG)": strongyloidesValue == 0 ? "<30": strongyloidesValue == -2 ? "-":strongyloidesValue ,
                  "Trichuris (EPG)": rows.trichuris == 0 ? "<30" : rows.trichuris == -2 ? "-":rows.trichuris,
                  "Monezia (EPG)": rows.monezia == 0 ? "<30" : rows.monezia == -2 ? "-":rows.monezia,
                  "Parascaris (EPG)": "Not Tested",
                  "Anoplocephala (EPG)": "Not Tested",
                  "Liver Fluke (EPG)": "Not Tested",
                  "Rumen Fluke (EPG)": "Not Tested"
                });
            }
              else if (rows.animaltype ==3){
                arrays.push({
                    "Sample ID": rows.cow_id,
                    "UUID": rows.uuid,
                    "Status": status,
                    "Test Type" :"FEC Test",
                    "Animal Type": "Equine",
                    "Sample Type": rows.sampletype == 1 ?"Individual" : "Pooled",
                    "Date of Test": rows.created_date,              
                    "Strongyle (EPG)": rows.strongyle == 0 ? "<30" : rows.strongyle == -2 ? "-" : rows.strongyle,
                    "Parascaris (EPG)": rows.parascaris == 0 ? "<30" : rows.parascaris == -2 ? "-" : rows.parascaris,
                    "Coccidia (OPG)": coccidia_status,
                    "Strongyloides (EPG)": strongyloidesValue == 0 ? "<30" : strongyloidesValue == -2 ? "-": strongyloidesValue,
                    "Anoplocephala (EPG)": rows.anoplocephala == 0 ? "<30" : rows.anoplocephala == -2 ? "-": rows.anoplocephala,
                    "Nematodirus (EPG)": "Not Tested",
                    "Trichuris (EPG)": "Not Tested",
                    "Monezia (EPG)": "Not Tested",
                    "Liver Fluke (EPG)": "Not Tested",
                    "Rumen Fluke (EPG)": "Not Tested"

                  });

              }

          }
          if (rows.test_type==3){
            let animal_type = 'Bovine';
            if (rows.animaltype ==2){
              animal_type = 'Ovine';
            }
            else if (rows.animal_type == 4){
              animal_type = 'Caprine';
            }
            
            const status = 
              rows.status == 'red'
                ? "Infected"
                : rows.status == "green"
                ? "Not Infected"
                : rows.status == "failed"
                ? "Failed"
                :"In Progress";

                
            let liver_fluke = "No Eggs Detected";
            let rumen_fluke = "No Eggs Detected";

            if(rows.liverfluke > 0){
              liver_fluke = rows.liverfluke;

            }
            if(rows.rumenfluke > 0){
              rumen_fluke = rows.rumenfluke ;
            }
            arrays.push({
            "Sample ID": rows.cow_id,
            "UUID": rows.uuid,
            "Status": status,
            "Test Type" :"Fluke Test",
            "Animal Type": animal_type,
            "Sample Type": rows.sampletype == 1 ?"Individual" : "Pooled",
            "Date of Test": rows.created_date,              
            "Liver Fluke (EPG)": liver_fluke,
            "Rumen Fluke (EPG)": rumen_fluke,
            "Strongyle (EPG)": "Not Tested",
            "Parascaris (EPG)": "Not Tested",
            "Coccidia (OPG)": "Not Tested",
            "Strongyloides (EPG)": "Not Tested",
            "Anoplocephala (EPG)": "Not Tested",
            "Nematodirus (EPG)": "Not Tested",
            "Trichuris (EPG)": "Not Tested",
            "Monezia (EPG)": "Not Tested",

          });

          }

        });
        const items = arrays;
        const replacer = (key,value) => (value === null ? "": value);
        const header = Object.keys(items[0]);
        const csv = [
              header.join(","), // header row first
              ...items.map((row) =>
                header
                  .map((fieldName) =>
                    JSON.stringify(row[fieldName], replacer).replace(/\\"/g, '""')
                  )
                  .join(",")
              ),
            ].join("\r\n");                   
            this.manageDownload(csv);
      })        
      .catch((err) => {
        alert(err);
      });

    },
    async Downloadhistory(){
      if (this.form.from > this.form.to){
        // console.log("Here is the value of to and from",this.form.from," and ", this.form.to);
        alert("'From' date cannot be greater than 'To' date");
      }
      else{
        // console.log("Here is the value of to and from.............",this.form.from," and ", this.form.to);
      this.store
      .dispatch("Tests/DownloadMultiResultshistory",{from:this.form.from, to:this.form.to} )
      .then((result) => {
        var arrays = [];
        result.forEach((rows) =>{
          if (rows.test_type !=3){
            console.log("Here is the value of rows coccidia - ",rows.coccidia);
            const coccidiaValue = rows.coccidia;
            let strongyloidesValue = rows.strongyloides;
            let coccidia_status = "";


            if (coccidiaValue > 0 && coccidiaValue < 1000) {
              coccidia_status = "Low";
            } else if (coccidiaValue >= 1000 && coccidiaValue < 2000) {
              coccidia_status = "Medium";
            } else if (coccidiaValue >= 2000 && coccidiaValue < 4000) {
              coccidia_status = "High";
            } else if (coccidiaValue >= 4000) {
              coccidia_status = "Very High";
            } else if (coccidiaValue == 0){
              coccidia_status = "<30";
            } else if (coccidiaValue == -1){
              coccidia_status = "Invalid";
            } else if(coccidiaValue == -2){
              coccidia_status = "-";
            }
            

            if (rows.strongyloides == -1){
              strongyloidesValue = "Invalid";

            }


             
            const status = 
              rows.status == 'red'
                ? "Infected"
                : rows.status == "green"
                ? "Not Infected"
                : rows.status == "failed"
                ? "Failed"
                :"In Progress";



              if(rows.animaltype!=3){
                let animal_type = 'Bovine';
                if (rows.animaltype ==2){
                  animal_type = 'Ovine';
                }
                else if (rows.animal_type == 4){
                  animal_type = 'Caprine';
                }

         
              arrays.push({
                  "Sample ID": rows.cow_id,
                  "UUID": rows.uuid,
                  "Status": status,
                  "Test Type" : "FEC Test",
                  "Animal Type": animal_type,
                  "Sample Type": rows.sampletype == 1 ?"Individual" : "Pooled",
                  "Date of Test": rows.created_date,              
                  "Strongyle (EPG)": rows.strongyle == 0 ? "<30" : rows.strongyle == -2 ? "-": rows.strongyle,
                  "Nematodirus (EPG)": rows.nematodirus == 0 ? "<30": rows.nematodirus == -2 ? "-" : rows.nematodirus,
                  "Coccidia (OPG)": coccidia_status,
                  "Strongyloides (EPG)": strongyloidesValue == 0 ? "<30": strongyloidesValue == -2 ? "-":strongyloidesValue ,
                  "Trichuris (EPG)": rows.trichuris == 0 ? "<30" : rows.trichuris == -2 ? "-":rows.trichuris,
                  "Monezia (EPG)": rows.monezia == 0 ? "<30" : rows.monezia == -2 ? "-":rows.monezia,
                  "Parascaris (EPG)": "Not Tested",
                  "Anoplocephala (EPG)": "Not Tested",
                  "Liver Fluke (EPG)": "Not Tested",
                  "Rumen Fluke (EPG)": "Not Tested"
                });
            }
              else if (rows.animaltype ==3){
                arrays.push({
                    "Sample ID": rows.cow_id,
                    "UUID": rows.uuid,
                    "Status": status,
                    "Test Type" :"FEC Test",
                    "Animal Type": "Equine",
                    "Sample Type": rows.sampletype == 1 ?"Individual" : "Pooled",
                    "Date of Test": rows.created_date,              
                    "Strongyle (EPG)": rows.strongyle == 0 ? "<30" : rows.strongyle == -2 ? "-" : rows.strongyle,
                    "Parascaris (EPG)": rows.parascaris == 0 ? "<30" : rows.parascaris == -2 ? "-" : rows.parascaris,
                    "Coccidia (OPG)": coccidia_status,
                    "Strongyloides (EPG)": strongyloidesValue == 0 ? "<30" : strongyloidesValue == -2 ? "-": strongyloidesValue,
                    "Anoplocephala (EPG)": rows.anoplocephala == 0 ? "<30" : rows.anoplocephala == -2 ? "-": rows.anoplocephala,
                    "Nematodirus (EPG)": "Not Tested",
                    "Trichuris (EPG)": "Not Tested",
                    "Monezia (EPG)": "Not Tested",
                    "Liver Fluke (EPG)": "Not Tested",
                    "Rumen Fluke (EPG)": "Not Tested"

                  });

              }

          } 
          if (rows.test_type==3){
            let animal_type = 'Bovine';
            if (rows.animaltype ==2){
              animal_type = 'Ovine';
            }
            else if (rows.animal_type == 4){
              animal_type = 'Caprine';
            }
            const status = 
              rows.status == 'red'
                ? "Infected"
                : rows.status == "green"
                ? "Not Infected"
                : rows.status == "failed"
                ? "Failed"
                :"In Progress";

            let liver_fluke = "No Eggs Detected";
            let rumen_fluke = "No Eggs Detected";

            if(rows.liverfluke > 0){
              liver_fluke = rows.liverfluke;
            }
            else if(rows.liverfluke == -2){
              liver_fluke = "-";
            }
            if(rows.rumenfluke > 0){
              rumen_fluke = rows.rumenfluke ;
            }
            else if(rows.rumenfluke == -2){
              rumen_fluke = "-";
            }


            arrays.push({
            "Sample ID": rows.cow_id,
            "UUID": rows.uuid,
            "Status": status,
            "Test Type" :"Fluke Test",
            "Animal Type": animal_type,
            "Sample Type": rows.sampletype == 1 ?"Individual" : "Pooled",
            "Date of Test": rows.created_date,              
            "Liver Fluke (EPG)": liver_fluke,
            "Rumen Fluke (EPG)": rumen_fluke,
            "Strongyle (EPG)": "Not Tested",
            "Parascaris (EPG)": "Not Tested",
            "Coccidia (OPG)": "Not Tested",
            "Strongyloides (EPG)": "Not Tested",
            "Anoplocephala (EPG)": "Not Tested",
            "Nematodirus (EPG)": "Not Tested",
            "Trichuris (EPG)": "Not Tested",
            "Monezia (EPG)": "Not Tested",

          });

          }

        });
        const items = arrays;

        // Configuration for json2csv
        const fields = Object.keys(items[0]);
        const opts = { fields };
        try {
            const parser = new Parser(opts);
            const csv = parser.parse(items);
            
            this.manageDownload(csv);
                        
          } catch (err) {
            console.error(err);
          }
      })
      .catch((err) => {
        console.log(err);
        alert("There are no tests performed for the selected date.");
      });
    }
    },
    async DownloadCombined(){
      // add the  code to throw error when download combined is called for different test types/ animal types
      // add another line of code to download combined results for fluke tests...
      let {id_arr,ani_check,test_check} = this.testtype_animaltype_check(this.downloadList);
      // console.log(id_arr,ani_check,test_check);

      if((ani_check)&&(test_check)&&(this.downloadList.length<=8)){

        this.store
        .dispatch("Tests/DownloadCombinedResults",id_arr)
        .then((result) => {
          html2pdf(result.html, {
              margin: [10, 10, 10, 10],
              filename: "Combined_FEC.pdf",
              type: "jpeg",
              quality: 100,
              html2canvas: { dpi: 900, letterRendering: true, scale: 3 },
              jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            });
        })

      }

      let errorMessage = '';
      if (!ani_check && !test_check) {
        errorMessage = 'Please select the same Animal Type and Test Type';
      } else if (!ani_check) {
        errorMessage = 'Please select the same Animal Type';
      } else if (!test_check) {
        errorMessage = 'Please select the same Test Type';
      }
      if (this.downloadList.length > 8) {
        if (errorMessage) {
          errorMessage += ' and no more than 8 tests at a time.';
        } else {
          errorMessage = 'Please select no more than 8 tests at a time.';
        }
      }
      if (errorMessage) {
        alert(errorMessage);
      }
    },
    testtype_animaltype_check(downlist){

      let animal_arr  = [];
      let test_arr = [];
      let id_arr = [];
      for(let i=0;i<downlist.length;i++){
        // console.log("Reached here!",this.downloadList[i]);
        let selection = downlist[i].split(',')
        id_arr.push(selection[0]);
        test_arr.push(selection[1]);
        animal_arr.push(selection[2]);
      }
      let ani_check = this.is_samecheck(animal_arr);
      let test_check = this.is_samecheck(test_arr);
      // console.log("Here is the value from withing the function - ",id_arr,ani_check,test_check);
      return {id_arr:id_arr,
        ani_check:ani_check,
        test_check:test_check};

    },
    is_samecheck(arr){
   
      const firstarr = arr[0]
      for(let j=0;j < arr.length; j++){
        if(arr[j] != firstarr){
          return false;
        }
      }
      return true;
    },

    addToList(id) {
      if (this.downloadList.includes(id)) {
        var index = this.downloadList.indexOf(id);
        this.downloadList.splice(index, 1);
      } else {
        this.downloadList.push(id);
      }
    },
    naCheck(herd){
      if(herd){
        return herd;
      }
      else{
        return "NA";
      }
    }
    ,
    testName(testtype){
      if(testtype==3){
        return "FLUKE";
      }
      else{
        return "FEC";
      }
    },
    animalType(animal){
      if(animal ==1){
        return "Bovine";
      }
      else if(animal ==2){
        return "Ovine";
      }
      else if(animal ==3){
        return "Equine";
      }
      else if(animal ==4){
        return "Caprine";
      }
      else if(animal ==5){
        return "Canine";
      }
      else if(animal ==6){
        return "Feline";
      }
    }
    ,
    async DownloadFECmulti() {
      let result_array = [];
      const downloadFEC_multi = this.downloadList.map(async(id) => {
        const result = await this.store.dispatch("Tests/DownloadFECmulti", { id });
        result_array = result_array.concat(result);
        // console.log('from inside the loop',result);
        //return result;
      });
     
     const results = await Promise.all(downloadFEC_multi);
     console.log('Value of results',results);
    // console.log('from outside the loopsss',result_array);
      // code to block of data to another function to create the pdf....
      await this.store.dispatch("Tests/MultipartCreate",{result_array});
      //console.log('balhhhhhfrom outside the loop',resultupdated);

      return;
    

    },
    async DownloadFEC() {
      const downloadFEC = this.downloadList.map((id) => {
        return this.store.dispatch("Tests/DownloadFEC", { id });
      });

      const results = await Promise.all(downloadFEC);
      
      this.pdfArray = await Promise.all(
        results.map(async (result) => {
          const worker = await html2pdf()
            .set({
              margin: [0, 8, 15, 0],
              type: "jpeg",
              quality: 100,
              html2canvas: { dpi: 900, letterRendering: true, scale: 3 },
              jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            })
            .from(result.html)
            .output("arraybuffer");

          return worker;
        })
      );

      const merged = await this.mergePdfs(this.pdfArray);

      this.downloadPdf(merged);
      
      return;
    },
    async mergePdfs(pdfsToMerge) {
      const mergedPdf = await PDFDocument.create();

      const createInnerPromise = async (arrayBuffer) => {
        const pdf = await PDFDocument.load(arrayBuffer);
        return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      };

      const outerPromise = pdfsToMerge.map((arrayBuffer) => {
        const innerPromise = createInnerPromise(arrayBuffer);
        return innerPromise;
      });

      const resultOuterPromise = await Promise.all(outerPromise);

      resultOuterPromise.forEach((pageArray) => {
        pageArray.forEach((page) => {
          mergedPdf.addPage(page);
        });
      });

      return await mergedPdf.save();
    },
    downloadPdf(bytes) {
      const blob = new Blob([bytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "FEC.pdf";
      link.click();
    },
    GetTests() {
      if (this.Tests.length == 0) {
        this.store
          .dispatch("Tests/GetTests", this.form)
          .then((result) => {
            this.Tests.push(...result.data);
            this.form.offset = this.form.offset + this.form.limit;
            this.count = this.Tests.length;
            this.TotalTests = result.total;
            // console.log('here getresult',result);
            
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.TotalTests > 0 && this.count < this.TotalTests) {
        this.store
          .dispatch("Tests/GetTests", this.form)
          .then((result) => {
            this.Tests.push(...result.data);
            this.form.offset = this.form.offset + this.form.limit;
            this.count = this.Tests.length;
            this.TotalTests = result.total;
            console.log('here getresult',result);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    TimeParse(time) {
      let date = new Date(time);
      let returntime = `${("0" + date.getDate()).slice(-2)}/${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(
        -2
      )}:${("0" + date.getMinutes()).slice(-2)}`;

      return returntime;
    },
    getIndex(index) {
      return this.TotalTests - index;
    },
  //   converttestidtoUUID(id){
  //     let combinationCount = 0;
      
  //     if (id > 104000){
  //       id = id % 104000;
  //     }
  //     const targetCombinationNumber = id;
  //     for (let num1 = 0; num1 <= 9; num1++) {
  //       for (let num2 = 0; num2 <= 9; num2++) {
  //         for (let num3 = 0; num3 <= 9; num3++) {
  //           for (let letter = 'A'.charCodeAt(0); letter <= 'Z'.charCodeAt(0); letter++) {
  //             for (let position = 1; position <= 4; position++) {
  //               let combination = '';
  //               for (let i = 1; i <= 4; i++) {
  //                 if (i === position) {
  //                   combination += String.fromCharCode(letter);
  //                 } else {
  //                   combination += String(i <= 3 ? num1 : num2);
  //                 }
  //               }
  //               combinationCount++;

  //               if (combinationCount === targetCombinationNumber) {
  //               //  console.log(`The combination is: ${combination}`);
  //                 return combination;
  //                 // Break out of all loops
  //                 // num1 = 10; num2 = 10; num3 = 10; letter = 'Z'.charCodeAt(0); position = 5;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }

  //   },  
  // convertBussidToBussId(bussid) {
    
  //   let combinationCount = 0;
  // const targetCombinationNumber = bussid;
  // for (let num1 = 0; num1 <= 9; num1++) {
  //       for (let num2 = 0; num2 <= 9; num2++) {
  //          for (let letter = 'A'.charCodeAt(0); letter <= 'Z'.charCodeAt(0); letter++) {
  //             for (let position = 1; position <= 3; position++) {
  //               let combination = '';
  //               for (let i = 1; i <= 3; i++) {
  //                 if (i === position) {
  //                   combination += String.fromCharCode(letter);
  //                 } else {
  //                   combination += String(i <= 2 ? num1 : num2);
  //                 }
  //               }
  //               combinationCount++;

  //               if (combinationCount === targetCombinationNumber) {
  //                // console.log(`The combination is: ${combination}`);
  //                 return combination;
  //                 // Break out of all loops
  //                 // num1 = 10; num2 = 10; num3 = 10; letter = 'Z'.charCodeAt(0); position = 5;
  //               }
  //             }
  //           }
  //         }
  //     }
  //   },
  //   getuniqueID(testtype,bussid,id){
  //     console.log(id);
     
  //    let type = "W"
  //    if(testtype==3){
  //      type = "F";
  //    }
  //    let buss_id = this.convertBussidToBussId(bussid);
  //    let uuid = this.converttestidtoUUID(parseInt(id));
  //    let Year = new Date().getFullYear();

  
     
  //    return type + `${buss_id}` +'-'+ `${uuid}` +'-'+ `${Year.toString()}`


  //   },
    handleScroll(e) {

      // console.log("scrolling properly!!!!!");
      e.preventDefault();
      this.maxScroll =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      this.scroll = window.scrollY || window.scrollTop;
      if (this.scroll == this.maxScroll) {
        console.log("Reached the end of the page!")
      }
    },
    GetTestSearch() {
      this.Tests = [];
      this.form.offset = 0;

      this.GetTests();
    },
    GotoInfo(id, index) {
      index = this.getIndex(index);
      this.$router.push({
        name: "Result_View",
        params: { id: id, index: index },
      });
    },
    GetMoreTest(){
      this.GetTests();
    },    
    createZipFile(csv) {      
        try {                    
          const Minizip = require('minizip-asm.js');
          const mz = new Minizip();           
          const password = Math.random().toString(36).slice(-8).padEnd(8, 'x');
          mz.append('export.csv', new TextEncoder().encode(csv), {
            password: password, 
            method: Minizip.ZIP_DEFLATED,
            level: 6
          });            
          const zip = mz.zip();            
          const blob = new Blob([zip], {type: "application/zip"});            
          const url = URL.createObjectURL(blob);
          
          const link = document.createElement("a");
          link.href = url;
          const timestamp = Date.now().toString().slice(-4);
          const fileName = `export_${timestamp}.zip`;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);    
          return { password, fileName };       
        } catch (error) {
          console.error("Failed to download zip file:", error);
          throw error;  
        }      
    },
    async notifyCompletion(data) {
      this.store
      .dispatch("Tests/NotifyCompletion",data)      
      .then((result) => {
        console.log(result);
      }).catch((err) => {
        console.log(err);
      });      
    },
    async manageDownload(csv) {
      const result = await this.createZipFile(csv);
      this.notifyCompletion(result);
      alert("The password for the downloaded zip file will be sent to your registered email address shortly. Please check your email.")            
      return "data";
    },
   
  },
  mounted() {
    
    this.GetTests();
    window.addEventListener("scroll", this.handleScroll);
    console.log("Mounting properly????");
    }, 
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
    console.log("Reaching here??? unmount?");
  },
  watch: {
    onlyuser: function (value) {
      console.log(value);
      this.form.onlyuser = value;
      this.GetTestSearch();
    },
  },
};
</script>


<style>
.test-placeholder {
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
}
.test-result {
  margin: 0 auto;
  height: 90px;
  width: 80%;
  background: #e4e7ee;
  border-radius: 3px;
  border: 1px solid #dddddd;
}
.test-block {
  width: 16%;
  height: 100%;
  display: inline-block;
}

.test-id {
  font-size: 20px;
  font-weight: bolder;
  height: 35px;
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  line-height: 35px;
  display: block;
  float: left;
}
.test-animalid small {
  position: absolute;
  line-height: 25px;
  font-size: 12px;
  display: block;
  color: inherit;
}
.test-animalid {
  font-size: 17px;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  line-height: 60px;
  display: block;
  float: left;
  overflow: hidden;
  box-sizing: border-box;
}

.test-status {
  font-size: 20px;
  display: block;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  float: right;
  line-height: 45px;
  margin: 10px 10px 0px 0px;
  border-radius: 50px;
}
.test-date {
  font-size: 17px;
  height: 40px;
  width: 100%;
  padding-right: 10px;
  line-height: 35px;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  text-align: right;
}
.test-result:hover {
  border: 1px solid #35bba4;
}
</style>